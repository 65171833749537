#about .about1 {
  background-color: #F5F8FF;
}

#about .about1 .container {
  display: flex;
  gap: 4rem;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 80%;
}

#about .about1 .container .left .title {
  text-transform: uppercase;
  font-weight: 400;
}

#about .about1 .container .left .subtitle {
  font-size: 2rem;
}

#about .about1 .container .left .mobile {
  display: none;
  flex-direction: column;
  width: 100%;
}

#about .about1 .container .left .text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .left #ctaWpp {
  text-align: left;
}

#about .about1 .container .left #ctaWpp img {
  width: 60%;
}

#about .about1 .container .right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#about .about1 .container .right .item1,
#about .about1 .container .left .mobile .item1 {
  z-index: 2;
  text-align: left;
}

#about .about1 .container .right .item2,
#about .about1 .container .left .mobile .item2 {
  z-index: 1;
  margin-top: -4rem;
  text-align: left;
}

#about .about1 .container .right .item3,
#about .about1 .container .left .mobile .item3 {
  margin-top: -4rem;
  text-align: left;
}

#about .about1 .container .right .item1,
#about .about1 .container .right .item2,
#about .about1 .container .right .item3 {
  text-align: right;
}

#about .about1 .container .right .item1 img,
#about .about1 .container .right .item2 img,
#about .about1 .container .right .item3 img,
#about .about1 .container .left .mobile .item1 img,
#about .about1 .container .left .mobile .item2 img,
#about .about1 .container .left .mobile .item3 img {
  width: 80%;
}

#about .about2 {
  background-color: #243869;
}

#about .about2 .container {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

#about .about2 .container .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  max-width: 30%;
}

#about .about2 .container .item .top {
  display: flex;
  gap: .5rem;
}

#about .about2 .container .item .top figure {
  max-width: 30px;
}

#about .about2 .container .item .top .top_title {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
}

#about .about2 .container .item .text {
  width: 80%;
}

#about .about3 {
  display: flex;
  align-items: center;
  gap: 2rem;
  background-color: #EFF2F5;
}

#about .about3 .left {
  padding: 2rem 0 2rem 10vw;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about3 .left .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 7px;
  padding: 3rem 2rem;
}

#about .about3 .left .item .item_title {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

#about .about3 .left .item:nth-child(1) {
  background-color: #2570E1;
}

#about .about3 .left .item:nth-child(2) {
  background-color: #0F489E;
}

#about .about3 .left .item:nth-child(3) {
  background-color: #1A3658;
}

#about .about3 .left #ctaWpp {
  margin-top: 2rem;
}

#about .about3 .left #ctaWpp img {
  width: 47%;
}

#about .about3 .right {
  width: 50%;
}

#about .about3 .right img {
  max-height: 110vh;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .left {
    width: 100%;
  }

  #about .about1 .container .left .mobile {
    display: flex;
  }

  #about .about1 .container .left .subtitle {
    text-align: center;
  }

  #about .about1 .container .left .mobile .item1,
  #about .about1 .container .left .mobile .item2,
  #about .about1 .container .left .mobile .item3 {
    text-align: center;
  }

  #about .about1 .container .left .text {
    text-align: center;
  }

  #about .about1 .container .left #ctaWpp {
    text-align: center;
  }

  #about .about1 .container .left #ctaWpp img {
    width: 50%;
  }

  #about .about1 .container .right {
    display: none;
  }

  #about .about2 .container {
    flex-direction: column;
  }

  #about .about2 .container .item {
    max-width: 100%;
  }

  #about .about3 {
    flex-direction: column;
  }

  #about .about3 .left #ctaWpp {
    text-align: center;
  }

  #about .about3 .left #ctaWpp img {
    width: 50%;
  }

  #about .about3 .right {
    width: 100%;
    order: -1;
  }

  #about .about3 .right img {
    max-height: 100%;
    width: 100%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .left .mobile .item1 img,
  #about .about1 .container .left .mobile .item2 img,
  #about .about1 .container .left .mobile .item3 img {
    width: 90%;
  }

  #about .about1 .container .left #ctaWpp img {
    width: 60%;
  }

  #about .about3 .left #ctaWpp img {
    width: 60%;
  }
}