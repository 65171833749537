#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

#header .header1 .container .title {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#header .header1 .container .title span {
    color: white;
}

#header .header2 {
    background-color: #162534;
    display: flex;
}

#header .header2 .title {
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

#header .header3 .container .title span {
    color: var(--color-blue);
}

#header .header3 .container .gallery {
    display: grid;
    grid-template-columns: repeat(3, 20%);
    justify-content: center;
    gap: 3rem;
}

#header .header3 .container .gallery .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

#header .header3 .container .gallery .item figure img {
    width: 60%;
}

#header .header3 .container .gallery .item .text {
    text-transform: uppercase;
    color: #243869;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 70vh;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 60vh;
    }

    #header .header2 .title {
        font-size: 1.5rem;
    }

    #header .header3 .container .gallery {
        grid-template-columns: repeat(3, 25%);
        gap: 3rem;
    }

    #header .header3 .container .gallery .item figure img {
        width: 70%;
    }

    #header .header3 .container .gallery .item .text {
        font-size: 1rem;
        word-wrap: break-word;
        width: 100%;
    }
}