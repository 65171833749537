#menu {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 35%;
}

#menu figure {
    width: 100%;
    text-align: center;
}

#menu figure img {
    width: 70%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu {
        left: 20%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu {
        left: 5%;
    }

    #menu figure img {
        width: 50%;
    }
}