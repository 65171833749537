#success {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10rem;
}

#success .container .success-title {
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {

}