#footer .footer1 {
    background-color: #313131;
    border-top: 3px solid #FF0E63;
    margin-top: -1rem;
}

#footer .footer1 .container figure img {
    width: 10%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 15%;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container figure img {
        width: 20%;
    }

    #footer .footer1 {
        margin-top: -.6rem;
    }
}