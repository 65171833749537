#content .content1 {
  margin-bottom: 2rem;
}

#content .content1 .container {
  background-color: #162855;
  padding: 3rem 2rem;
  border-radius: 2rem;
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}

#content .content1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: white;
  width: 100%;
}

#content .content1 .container .left .title {
  color: #81FFBB;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 400;
}

#content .content1 .container .left .subtitle {
  color: white;
  text-transform: uppercase;
}

#content .content1 .container .left .mobile {
  display: none;
}

#content .content1 .container .left .text {
  width: 90%;
}

#content .content1 .container .left #ctaWpp {
  text-align: left;
}

#content .content1 .container .left #ctaWpp img {
  width: 45%;
}

#content .content1 .container .carousel {
  width: 90%;
}

#content .content2 {
  background: url("../../assets/content2.png") no-repeat center center;
  background-size: cover;
  height: 65vh;
}

#content .content2 .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  text-align: center;
  color: white;
  height: 100%;
}

#content .content2 .container .title {
  color: white;
  font-weight: 700;
}

#content .content3 {
  background-color: #EFF2F5;
}

#content .content3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content3 .container .title {
  text-align: center;
  color: #162855;
  text-transform: uppercase;
}

#content .content3 .container .carousel {
  width: 100%;
}

#content .content3 .container .carousel figure img {
  width: 90%;
  border-radius: 5px;
}

#content .content4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content4 .container .title {
  font-weight: 400;
  text-align: center;
}

#content .content4 .container .subtitle { 
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
}

#content .content4 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
}

#content .content4 .container .gallery .item {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content4 .container .gallery .item .stars img {
  width: 30%;
}

#content .content4 .container .gallery .item .text {
  font-style: italic;
  text-align: center;
}

#content .content4 .container .gallery .item .perfil img {
  width: 15%;
}

#content .content4 .container .gallery .item .name {
  color: var(--color-title);
  text-align: center;
  font-weight: bold;
  margin-top: -1.5rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container {
    flex-direction: column;
    text-align: center;
  }

  #content .content1 .container .left .mobile {
    display: block;
  }

  #content .content1 .container .left .mobile {
    width: 100%;
  }

  #content .content1 .container .left #ctaWpp {
    text-align: center;
  }
  
  #content .content1 .container .left #ctaWpp img {
    width: 50%;
  }

  #content .content1 .container .carousel {
    display: none;
  }

  #content .content2 {
    height: 50vh;
  }

  #content .content2 .container {
    height: 100%;
    justify-content: center;
  }

  #content .content4 .container .gallery {
    grid-template-columns: 90%;
    grid-template-rows: repeat(3, 1fr);
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container .left #ctaWpp img {
    width: 70%;
  }

  #content .content2 {
    height: 100%;
  }
}